/*
/acc/modelList
/acc/timezoneList
/acc/companyList
/acc/branchList/{regionId}
/acc/locationList/{branchId}
*/

//-----------------------fetchConfig 사용하지 않음
/*
import { axiosWithAuth } from './index';
// 화면: Asset Mgt. > Machine Status
// 항목: Add / Edit / Delete

// 내용: 모델 리스트, 타임존 리스트 (캐싱 예정)
// response data: 
//      dataList: [{id: 'MDL-0001-0000000000000000KC10', name: 'KC10'}]

function ReadModelList(success, error) {    
    return axiosWithAuth.get('/api/v1/acc/modelList')
        .then(res => success(res.data))
        .catch(error);   
}

function ReadTimezoneList(success, error) {    
    return axiosWithAuth.get('/api/v1/acc/timezoneList')
        .then(res => success(res.data))
        .catch(error);
}

// 내용: 회사, 지점, 위치 리스트
// response data: 
//      dataList: [{id: 1, name: 'New York'}]
 
function ReadcompanyList(success, error) {    
    return axiosWithAuth.get('/api/v1/acc/companyList')
        .then(res => success(res.data))
        .catch(error);
}
function ReadbranchList(companyId, success, error) {    
    return axiosWithAuth.get('/api/v1/acc/branchList/' + companyId)
        .then(res => success(res.data))
        .catch(error);
}

function ReadLocationList(branchId, success, error) {    
    return axiosWithAuth.get('/api/v1/acc/locationList/' + branchId)
        .then(res => success(res.data))
        .catch(error);
}
*/

//-----------------------fetchConfig 사용
import { getAxiosAuth } from './index';

function ReadModelList(success, error) {
    return getAxiosAuth()
        .then(service => service.get('/api/v1/acc/modelList'))
        .then(res => success(res.data))
        .catch(error);
}

function ReadMachineUserAuthList(success, error) {
    return getAxiosAuth()
        .then(service => service.get('/api/v1/acc/machineUserAuth'))
        .then(res => success(res.data.payload))
        .catch(error);
}

function ReadTimezoneList(success, error) {
    return getAxiosAuth()
        .then(service => service.get('/api/v1/acc/timezoneList'))
        .then(res => success(res.data))
        .catch(error);
}

function ReadcompanyList(success, error) {
    return getAxiosAuth()
        .then(service => service.get('/api/v1/acc/companyList'))
        .then(res => success(res.data))
        .catch(error);
}
function ReadbranchList(companyId, success, error) {
    return getAxiosAuth()
        .then(service => service.get('/api/v1/acc/branchList/' + companyId))
        .then(res => success(res.data))
        .catch(error);
}
function ReadLocationList(branchId, success, error) {
    return getAxiosAuth()
        .then(service => service.get('/api/v1/acc/locationList/' + branchId))
        .then(res => success(res.data))
        .catch(error);
} 

export { ReadModelList, ReadMachineUserAuthList, ReadTimezoneList,
    ReadcompanyList, ReadbranchList, ReadLocationList };