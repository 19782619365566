import { ReadModelList, ReadMachineUserAuthList, ReadTimezoneList } from '@/service/acc'

const accStore = {
    namespaced: true,
    state: {
      model: null,
      mUserAuth: null,
      timezone: null,
    },
    getters: {
      'model': state => state.model,
      'mUserAuth': state => state.mUserAuth,
      'timezone': state => state.timezone,
    },
    mutations: {
      setModelData(state, data) {
          state.model = data;
      },
      setMUserAuthData(state, data) {
        state.mUserAuth = data;
      },
      setTimezoneData(state, data) {
        state.timezone = data;
      },
    },
    actions: {
      async fetchModel ({commit, state}) {
        if(state.model == null) {
          return ReadModelList (
            (res) => {
              commit('setModelData', res);
              return res;
            },
            (error) => {
              console.error(error);
              throw error;
            }
          )
        } else {
          return Promise.resolve(state.model);
        }
      },
      async fetchMUserAuth ({commit, state}) {
        if(state.mUserAuth == null) {
          return ReadMachineUserAuthList (
            ({dataList}) => {
              commit('setMUserAuthData', dataList);
              return dataList;
            },
            (error) => {
              console.error(error);
              throw error;
            }
          )
        } else {
          return Promise.resolve(state.mUserAuth);
        }
      },
      async fetchTimezone ({commit, state}) {
        if(state.timezone == null) {
          return ReadTimezoneList (
            (res) => {
              commit('setTimezoneData', res);
              return res;
            },
            (error) => {
              console.error(error);
              throw error;
            }
          )
        } else {
          return Promise.resolve(state.timezone);
        }
      },
    }
}

export default accStore